<template>
  <form style="width: 800px; max-width: 100%" @submit.prevent="save" v-if="personas.length && guardia.id">
    <h3 class="card-title">Editar guardia</h3>
    <div class="cols2">
      <suggest
        id="empleado"
        label="Empleado"
        placeholder="Buscar empleado.."
        :data="personas"
        :items="personaList"
        :value="empleado"
        @selected="empleadoSelected"
        :required="enviado && !empleado"
      ></suggest>
    </div>
    <div class="cols2">
      <pd-input
        v-model="guardia.guardia_desde"
        id="desde"
        label="Fecha inicio guardia"
        placeholder="Fecha de inicio de guardia"
        type="date"
        :required="enviado && !guardia.guardia_desde"
      ></pd-input>
      <pd-input
        v-model="guardia.guardia_hasta"
        id="hasta"
        label="Fecha finalización guardia"
        placeholder="Fecha de finalización de guardia"
        type="date"
        :required="enviado && !guardia.guardia_hasta"
      ></pd-input>
    </div>
    <button class="btn btn-primary mt-2" type="submit">Guardar</button>
  </form>
</template>
<script>
import { PersonasService } from '../../personas/services/PersonasServices';
import Suggest from '@/components/elements/Suggest';
import GuardiasServices from '../services/GuardiaServices';
import Swal from 'sweetalert2';
export default {
  name: 'EditGuardia',
  components: {
    Suggest,
  },
  props: {
    guardiaToEdit: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      enviado: false,
      guardia: {
        empleado_id: null,
        area_id: null,
        gerencia_id: null,
        guardia_desde: null,
        guardia_hasta: null,
      },
      areasConGuardia: [
        0,
        2, //Acueducto RC
        6, //Acueducto Oeste
        7, //Oficina técnica
        9, //Servicios generales
      ],
      empleado: null,
      personaList: PersonasService.dataList(),
      personas: [],
    };
  },
  async mounted() {
    this.guardia = Object.assign({},this.guardiaToEdit)
    this.personas = await PersonasService.api.getAllGuardias()
    this.empleado = this.personas.find(persona => persona.id === this.guardia.empleado_id)
  },
  methods: {
    save() {
      this.enviado = true;
      if (
        !this.guardia.empleado_id ||
        !this.guardia.guardia_desde ||
        !this.guardia.guardia_hasta
      ) {
        return;
      }
      GuardiasServices.api
        .editGuardia(this.guardia)
        .then((r) => {
          if (r.success == true) {
            Swal.fire('Éxito', `<p>Se guardó con éxito.</p>`, 'success');
            this.$emit("saved", r);
          } else {
            Swal.fire('Error', `<p>El empleado al que se le cargó la guardia está de licencia, por favor seleccione un reemplazo.</p>`, 'error');
          }
        })
        .catch((e) => {
          Swal.fire('Error', `<p>${e}</p>`, 'error');
        });
    },
    empleadoSelected(empleado) {
      if (empleado) {
        this.guardia.empleado_id = empleado.id;
        this.guardia.gerencia_id = empleado.gerencia_id;
        this.guardia.area_id = empleado.area_id;
        this.empleado = empleado;
      }
    },
  },
};
</script>
