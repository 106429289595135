<template>
  <div class="mx-1 mb-1 mt-1">
    <h3 class="card-title mb-1">Listado de guardias de:</h3>
    <h4 class="card-title text-secondary">{{ rowGuardiasMensual.empleado }}</h4>
    <table-list
      class="table-list-guardia"
      style="width: 600px; max-width: 100%;"
      :showFilter="false"
      :data="rowGuardiasMensual.guardias"
      :header="header"
      :body="body"
      @rowClicked="guardiaClicked"
    ></table-list>
  </div>
</template>

<script>
import TableList from "../../../components/elements/TableList.vue";

export default {
  name: "ListSelectedGuardias",
  components: {TableList},
  props: {
    rowGuardiasMensual: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      header: [
        {value: 'Guardia', sortable: true},
        {value: 'Desde', sortable: true},
        {value: 'Hasta', sortable: true},
        {value: 'Cargado por', sortable: true},
      ],
      body: [
        {
          data: [
            {value: 'empleado.nombre', class: 'text-primary important'},
            {value: 'area.descripcion', class: 'text-small text-secondary'}
          ]
        },
        {
          class: "w-100",
          data: [{
            value: "guardia_desde",
            label: "Desde",
            mutator: this.normalDate,
          },],
        },
        {
          class: "w-100",
          data: [{
            value: "guardia_hasta",
            label: "Hasta",
            mutator: this.normalDate,
          },],
        },
        {
          class: "w-100",
          data: [{
            value: "cargado_por_persona.nombre",
            label: "Cargado por",
          }
          ],
        },
      ]
    }
  },
  methods: {
    guardiaClicked(guardia) {
      this.$emit("selected", guardia)
    }
  }
}
</script>

<style scoped>

</style>
