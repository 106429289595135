<template>
  <div class="card" style="max-width: 100%" v-if="guardias">
    <h3 class="card-title">Listado de guardias</h3>
    <div class="mt-3" v-if="this.canCreateOrEdit">
      <button class="btn btn-primary" @click.prevent="showAdd = true">Cargar guardia</button>
    </div>
    <div class="cols2 mb-1 mt-1">
      <div class="cols2 w-100">
        <pd-input v-model="filtro_desde" id="filtro-desde" label="Desde" type="date"/>
        <pd-input v-model="filtro_hasta" id="filtro-desde" label="Hasta" type="date"/>
      </div>
      <div class="cols2 w-100">
        <suggest
          id="area"
          label="Área"
          placeholder="Seleccione área.."
          :value="area"
          :data="areas"
          :items="itemsArea"
          @selected="areaSelected"
        ></suggest>
        <div style="align-self: end;">
          <button class="btn btn-info" @click.prevent="getGuardias">Buscar</button>
        </div>
      </div>
    </div>
    <div style="overflow-x: auto">
      <table-list
        ref="table-guardia"
        class="table-guardia"
        :showFilter="false"
        :data="body"
        :header="headerGuardias"
        :body="bodyGuardias"
        @rowClicked="guardiaClicked"
        :action-button="actionButton"
      ></table-list>
    </div>

    <Modal v-if="showAdd" @close="showAdd = false">
      <CreateGuardia
        slot="modal-body"
        @saved="saved"
        :desde="selectedDesdeGuardia"
        :hasta="selectedHastaGuardia"
      />
    </Modal>
    <Modal v-if="showEdit" @close="showEdit = false">
      <EditGuardia
        :guardiaToEdit="guardiaSelected"
        slot="modal-body"
        @saved="edited"
      />
    </Modal>
    <Modal v-if="showSelectedGuardiasRow" @close="showSelectedGuardiasRow = false">
      <ListSelectedGuardias
        :row-guardias-mensual="selectedRow"
        slot="modal-body"
        @selected="selectGuardiaForActions"
      />
    </Modal>
  </div>
</template>

<script>
import {DateTime, Interval} from 'luxon';
import TableList from '../../../components/elements/TableList.vue';
import Modal from '../../../components/elements/Modal.vue';
import CreateGuardia from './CreateGuardia.vue';
import GuardiaServices from '../services/GuardiaServices';
import {AreaServices} from '../../areas/services/AreaServices';
import Suggest from "@/components/elements/Suggest";
import Swal from 'sweetalert2';
import EditGuardia from './EditGuardia.vue';
import Vuex from "vuex";
import ListSelectedGuardias from "./ListSelectedGuardias.vue";
import XLSX from "xlsx";

export default {
  name: 'ListGuardiasPorDia',
  components: {
    ListSelectedGuardias,
    TableList,
    Modal,
    CreateGuardia,
    Suggest,
    EditGuardia
  },
  data() {
    return {
      actionButton: {
        text: "Exportar selección a excel",
        action: () => this.downloadXLS()
      },
      area: null,
      areas: null,
      itemsArea: AreaServices.dataList(),
      area_id: null,
      guardias: null,
      guardiaSelected: null,
      showAdd: false,
      showEdit: false,
      permisosGuardia: [],
      selectedDesdeGuardia: null,
      selectedHastaGuardia: null,
      // NEW
      filtro_desde: null,
      filtro_hasta: null,
      daysOnMonthSelected: [],
      body: [],
      selectedRow: null,
      showSelectedGuardiasRow: false
    };
  },
  computed: {
    ...Vuex.mapState(["user"]),
    canCreateOrEdit() {
      return this.user.tipo === 'RRHH' || this.user.tipo === 'dev' || this.user.persona?.permiso_guardia?.can_create
    },
    headerGuardias() {
      return [
        {value: 'Empleado', sortable: true},
        ...this.daysOnMonthSelected.map((day) => {
          return {
            value: `<b class="d-flex center"><span>${day.setLocale('es').toFormat('ccc')}</span> <br> <span>${day.toFormat('dd/LL')}</span></b>`,
            sortable: false,
            class: "text-center"
          }
        }),
        {value: 'Total', sortable: true}
      ]
    },
    bodyGuardias() {
      return [
        {
          data: [{value: 'empleado', class: 'text-primary important'}, {
            value: 'area',
            class: 'text-small text-secondary'
          }]
        },
        ...this.daysOnMonthSelected.map((day) => {
          return {
            data: [{
              value: day.toFormat('dd/LL'),
              class: 'text-center text-bold'
            }]
          }
        }),
        {
          data: [{value: 'total', class: 'text-bold important'}]
        }
      ];
    },
  },
  async mounted() {

    const now = DateTime.now()
    if (this.$route.query.desde && this.$route.query.hasta) {
      this.filtro_desde = this.$route.query.desde
      this.filtro_hasta = this.$route.query.hasta
    } else {
      const desde = DateTime.fromObject({
        day: 21,
        month: now.toFormat('L'),
        year: now.toFormat('yyyy')
      })
      const hasta = DateTime.fromObject({
        day: 20,
        month: now.toFormat('L'),
        year: now.toFormat('yyyy')
      })
      if (now.day < 20) {
        this.filtro_desde = desde.minus({month: 1}).toFormat('yyyy-LL-dd')
        this.filtro_hasta = hasta.toFormat('yyyy-LL-dd')
      } else {
        this.filtro_desde = desde.toFormat('yyyy-LL-dd')
        this.filtro_hasta = hasta.plus({month: 1}).toFormat('yyyy-LL-dd')
      }
    }

    this.area_id = this.$route.query.area ?? null

    this.getAreas()
    await this.getGuardias();
  },
  methods: {
    async getAreas() {
      const responseAreas = await AreaServices.api.getAllForGuardias()
      this.areas = [
        {id: null, descripcion: 'Todas las áreas', gerencia: {descripcion: ''}},
        ...responseAreas
      ]

      if (this.$route.query.area) {
        this.area_id = this.$route.query.area
        this.area = this.areas.find(item => item.id === parseInt(this.area_id))
      } else {
        this.area = {id: null, descripcion: 'Todas las áreas', gerencia: {descripcion: ''}}
      }
    },
    async getGuardias() {
      const query = {
        area: this.area_id,
        desde: this.filtro_desde,
        hasta: this.filtro_hasta
      }

      if (
        this.$route.query.area !== query.area ||
        this.$route.query.desde !== query.desde ||
        this.$route.query.hasta !== query.hasta
      ) {
        this.$router.replace({query: query})
      }
      this.setIntervalDates()
      this.guardias = await GuardiaServices.api.getGuardias(query);
      this.formatGuardiasForTableList()
    },
    async saved() {
      this.showAdd = false
      this.selectedDesdeGuardia = null;
      this.selectedHastaGuardia = null;
      this.getGuardias()
    },
    edited() {
      this.showEdit = false
      this.guardiaSelected = null
      this.getGuardias()
    },
    formatDate(date) {
      return DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('dd/LL/yyyy');
    },
    areaSelected(area) {
      if (area) {
        this.area = area;
        this.area_id = area.id;
      }
    },
    guardiaClicked(row) {
      if (!this.canCreateOrEdit) {
        return;
      }
      this.selectedRow = row
      this.showSelectedGuardiasRow = true
    },
    selectGuardiaForActions(guardia) {
      Swal.fire({
        title: '¿Que acción desea realizar?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Editar',
        denyButtonText: `Eliminar`,
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        this.selectedRow = null
        this.showSelectedGuardiasRow = false
        if (result.isConfirmed) {
          this.guardiaSelected = guardia
          this.showEdit = true
        } else if (result.isDenied) {
          this.deleteGuardia(guardia)
        }
      })
    },
    deleteGuardia(guardia) {
      Swal.fire({
        title: '¿Seguro desea eliminar??',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, seguro',
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          GuardiaServices.api.deleteGuardia(guardia.id).then(() => {
            this.getGuardias()
          })
        }
      })
    },
    setIntervalDates() {
      this.daysOnMonthSelected = Interval.fromDateTimes(
        DateTime.fromFormat(this.filtro_desde, 'yyyy-LL-dd').startOf("day"),
        DateTime.fromFormat(this.filtro_hasta, 'yyyy-LL-dd').endOf("day"))
        .splitBy({day: 1}).map(d => d.start);
    },
    formatGuardiasForTableList() {
      const dates = []
      this.daysOnMonthSelected.forEach((day) => {
        dates[day.toFormat('dd/LL')] = "X"
      })
      const body = [];
      this.guardias.forEach((guardia) => {
        const inter = Interval.fromDateTimes(
          DateTime.fromFormat(guardia.guardia_desde, 'yyyy-LL-dd').minus({day: 1}),
          DateTime.fromFormat(guardia.guardia_hasta, 'yyyy-LL-dd')
        )
        guardia.interval = inter.splitBy({day: 1}).map(d => d.end)
        let currentRow = body.find((item) => item.empleado === guardia.empleado.nombre)
        if (!currentRow) {
          currentRow = {
            empleado: guardia.empleado.nombre,
            area: guardia.area.descripcion,
            guardias: [],
            total: 0
          }
          body.push(currentRow)
        }
        if (currentRow.empleado === guardia.empleado.nombre) {
          currentRow.guardias.push(guardia)
        }
        this.daysOnMonthSelected.forEach((day) => {
          const existsDay = guardia.interval.find((currentDay) => {
            return currentDay.toFormat('dd/LL') === day.toFormat('dd/LL')
          })
          if (existsDay) {
            if (currentRow[day.toFormat('dd/LL')] !== "X") {
              currentRow[day.toFormat('dd/LL')] = "X"
              currentRow.total++
            }
          } else if (!currentRow[day.toFormat('dd/LL')]) {
            currentRow[day.toFormat('dd/LL')] = ""
          }
        })
      })
      this.body = body
    },
    downloadXLS() {
      const filteredData = this.$refs['table-guardia'].filteredData
      const data = []
      const daysMapped = {
        ...this.daysOnMonthSelected.map((day) => {
          const a = {}
          a[`${day.setLocale('es').toFormat('ccc').toUpperCase()} ${day.toFormat('dd/LL')}`] = day.toFormat('dd/LL')
          return a
        })
      }
      for (const row of filteredData) {
        const item = {
          "Empleado": row.empleado,
          "Área": row.area,
        };
        for (const dayMapped of Object.values(daysMapped)) {
          const entries = Object.entries(dayMapped)[0]
          const header = entries[0]
          const dayIndex = entries[1]
          Object.entries(row).forEach((entry) => {
            console.log(entry)
            if (entry[0] === dayIndex) {
              item[header] = entry[1]
            }
          })
        }
        item['Total'] = row.total
        data.push(item)
      }

      let xlsData = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      const filename = `Guardias de ${this.area.descripcion} (${this.filtro_desde} a ${this.filtro_hasta})`
      XLSX.utils.book_append_sheet(workbook, xlsData, this.area.descripcion)
      XLSX.writeFile(workbook, `${filename}.xlsx`)

    }
  }
}
</script>

<style>
.table-guardia {
  max-height: 500px;
}

.table-guardia .table-list tr > th:first-child, .table-guardia .table-list tr > td:first-child {
  z-index: 1;
  position: sticky;
  left: 0;
}

.table-guardia .table-list tr > th:first-child {
  z-index: 3;
  background-color: var(--neutral-30);
}

.table-guardia .table-list tr > td:first-child {
  background-color: white;
  min-width: max-content;
}

.table-guardia .table-list thead {
  display: table-header-group;
}

.table-guardia .table-list th {
  padding: .8rem 1rem;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  z-index: 2;
  background-color: var(--neutral-30);
}

.table-guardia .table-list tr {
  display: table-row;
}

.table-guardia .table-list tr > td {
  border-left: 1px solid var(--neutral-30);
  padding: .5rem;
  display: table-cell;
}

.table-guardia .table-list tr > td .list-item {
  align-items: center;
  justify-content: center;
}

.table-guardia .table-list tr > td:first-child .list-item {
  justify-content: start;
}

.table-guardia .table-list tr > td:first-child * {
  white-space: nowrap;
}
</style>
